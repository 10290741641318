<template>
  <v-app>
    <v-banner
        class="lavBanner" 
        single-line
        height="400"
    >
      <v-img
          src="https://h5.ophyer.cn/official_website/banner/storeSolution-banner.png"
          height="100%"
          class="d-flex align-center"
        >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="d-flex justify-center">
          <v-card color="transparent" flat width="80%">
            <div class="d-flex flex-no-wrap justify-center" style="color: #fff">
              <div>
                <v-card-title class="white--text font-size-banner-title font-weight-bold px-0">
                  一键开店
                </v-card-title>
                <v-card-title class="white--text font-size-banner-tip px-0" style="line-height:30px;margin:0 auto;text-align: center;display: block;">
                  让您不费吹灰之力就拥有自己的商城
                </v-card-title>
              </div>
            </div>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>

    <!-- 您有流量但面对变现难题 -->
    <div class="first">
      <div class="public-title">
          <p>FLOW PROBLEM</p>
          <div>
            <span>您有流量但变现难</span>
          </div>
        </div>
      <div class="con">
        <v-row no-gutters justify='space-between'>
          <v-col v-for="(v, k) in flowList" :key="k" cols="12" sm="3" >
            <div class="box">
              <img :src='v.img' />
              <v-card-title class="font-weight-bold"> {{ v.title }} </v-card-title>
              <v-card-text class="tip font-size-14" v-html="v.p"> {{ v.p }} </v-card-text>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <!-- 我们具备的独特优势 -->
    <v-card :flat="true" class="serve">
      <div class="public-title">
        <p>OUR ADVANTAGE</p>
        <div>
          <span>我们具备的独特优势</span>
        </div>
      </div>
      <v-container  class="d-flex justify-md-space-around mt-4">
        <div class="carousel-container">
          <div class="c-item-1 box-shadow" :class="currentHover === 1 ? 'c-item-hover':''" @mouseover="currentHover = 1;">
            <div class="c-item-cover"></div>
          </div>
          <div class="c-item-2 box-shadow" :class="currentHover === 2 ? 'c-item-hover':''" @mouseover="currentHover = 2;">
            <div class="c-item-cover"></div>
          </div>
          <div class="c-item-3 box-shadow" :class="currentHover === 3 ? 'c-item-hover':''" @mouseover="currentHover = 3;">
            <div class="c-item-cover"></div>
          </div>
          <div class="c-item-4 box-shadow" :class="currentHover === 4 ? 'c-item-hover':''" @mouseover="currentHover = 4;">
            <div class="c-item-cover"></div>
          </div>
          <div class="c-item-5 box-shadow" :class="currentHover === 5 ? 'c-item-hover':''" @mouseover="currentHover = 5;">
            <div class="c-item-cover"></div>
          </div>
        </div>
      </v-container>
    </v-card>
    <!-- 您可使用的场景 -->
    <div class="first">
      <div class="public-title" style="margin-top:70px">
          <p>SCENES TO BE USED</p>
          <div>
            <span>您可使用的场景</span>
          </div>
        </div>
      <div class="con mt-4">
        <v-row no-gutters justify='space-between'>
          <v-col v-for="(v, k) in scenes" :key="k" cols="12" sm="4" >
            <v-card class="cardbox" :style="v.style">
              <v-card-title class="font-weight-bold pt-10 text-white"> {{ v.title }} </v-card-title>
              <v-card-text class="tipT"> {{ v.content }} </v-card-text>
              <v-img :src='v.img' />
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
    <!-- 能力介绍 -->
    <v-card
      class="warp-3 warp-box mt-2"
      width="1200"
      flat
    >
      <div class="public-title">
        <p>CAPABILITY INTRODUCTION</p>
        <div>
          <span>能力介绍</span>
        </div>
      </div>
      <div class="swiper-box" style="width:1200px;margin:0 auto;">
        <swiper ref="mySwiper" :options="swiperOption">
          <swiper-slide v-for="(v, k) in capability" :key="k">
            <div class="con">
              <v-row no-gutters justify='space-between' >
                <v-col cols="3">
                  <div class="box">
                    <img :src='v.img' />
                    <v-card-title class="font-weight-bold"> {{ v.title }} </v-card-title>
                    <v-card-text class="tip font-size-14" v-html='v.content '> {{ v.content }} </v-card-text>
                  </div>
                </v-col>
              </v-row>
            </div>
          </swiper-slide>
          <div class="swiper-button-next" slot="button-next"></div>
          <div class="swiper-button-prev" slot="button-prev"></div>
        </swiper>
      </div>
    </v-card>
      <!-- 能力介绍 -->
    <v-card
      class="warp-3 warp-box"
      width="1200"
      flat
    >
      <div class="public-title">
        <p>STORE BUILDING</p>
        <div>
          <span>3-5天搭建自己的微商城</span>
          <span>接入步骤</span>
        </div>
      </div>
      <v-img src='https://h5.ophyer.cn/official_website/other/storeSolution5.png' class="mb-6"/>
    </v-card>
  </v-app>
</template>

<script>
import FanDate from '../../static/FanData.js'
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  name: "ServiceContent",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      flowList:FanDate.OnlineRetailersStore.flowList,
      scenes:FanDate.OnlineRetailersStore.scenes,
      swiperOption: {
        slidesPerView: 4,
        spaceBetween: 25,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        prevButton:'.swiper-button-prev',
        nextButton:'.swiper-button-next',
      },
      capability:FanDate.OnlineRetailersStore.capability,
      currentHover: 1,
    }
  }
}
</script>

<style scoped lang='scss'>
.lavBanner {
  ::v-deep .v-banner__wrapper,
  ::v-deep .v-banner__content,
  ::v-deep .v-banner__text {
    padding: 0 !important;
    height: 100%;
  }
}
::v-deep.first {
  width: 1200px;
  margin: 0 auto;
  .con {
    margin-top: -12px;
  }
  .cardbox{
    margin: 0 auto;
    width: 300px;
    height: 342px;
    border-radius: 10px;
    .v-image__image--cover{
      background-size: initial !important;
    }
    .v-card__title{
      color: white !important;
    }
  }
  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    img {
      width: 120px;
      height: 120px;
    }
  }
  .tip {
    width: 266px;
    opacity: 0.6;
  }
  .tipT{
    width: 266px;
    opacity: 0.6;
    color: white;
  }
}
.serve{
  margin: 0 auto;
  .v-card__title{
    margin-top: 27px;
    padding: 6px 16px !important;
  }
  .v-card__text{
    line-height: 20px !important;
    padding: 6px 16px !important;
  }
  .v-card__title{
    width: 150px !important;
  }
  .public-title{
    margin-top: 30px;
  }
  .carousel-container {
    width: 1200px;
    height: 400px;
    position: relative;
    display: flex;
    flex-direction: row;
    animation: section-fade-in 1s ease 0s 1 normal both;
    .c-item-hover{
      width: 560px;
    }
    .c-item-1{
      background-image: url(https://h5.ophyer.cn/official_website/other/storeSolution2-1.jpg);
      margin-right: 10px;
      background-size: cover;
      background-position: 0;
      overflow: hidden;
      position: relative;
      height: 400px;
      border-radius: 15px;
      transition: width .4s;
      transition-timing-function: ease;
    }
    .c-item-1:not(.c-item-hover){
      width: 150px;
      transition: all .4s;
      transition-timing-function: ease;
    }
    .c-item-2{
      background-image: url(https://h5.ophyer.cn/official_website/other/storeSolution2-2.jpg);
      background-size: cover;
      background-position: 0;
      margin-right: 10px;
      overflow: hidden;
      position: relative;
      height: 400px;
      border-radius: 15px;
      transition: width .4s;
      transition-timing-function: ease;
    }
    .c-item-2:not(.c-item-hover){
      width: 150px;
      transition: all .4s;
      transition-timing-function: ease;
    }
    .c-item-3{
      background-image: url(https://h5.ophyer.cn/official_website/other/storeSolution2-3.jpg);
      background-size: cover;
      background-position: 0;
      margin-right: 10px;
      overflow: hidden;
      position: relative;
      height: 400px;
      border-radius: 15px;
      transition: width .4s;
      transition-timing-function: ease;
    }
    .c-item-3:not(.c-item-hover){
      width: 150px;
      transition: all .4s;
      transition-timing-function: ease;
    }
    .c-item-4{
      background-image: url(https://h5.ophyer.cn/official_website/other/storeSolution2-4.jpg);
      background-size: cover;
      background-position: 0;
      margin-right: 10px;
      overflow: hidden;
      position: relative;
      height: 400px;
      border-radius: 15px;
      transition: width .4s;
      transition-timing-function: ease;
    }
    .c-item-4:not(.c-item-hover){
      width: 150px;
      transition: all .4s;
      transition-timing-function: ease;
    }
    .c-item-5{
      background-image: url(https://h5.ophyer.cn/official_website/other/storeSolution2-5.jpg);
      background-size: cover;
      background-position: 0;
      overflow: hidden;
      position: relative;
      height: 400px;
      border-radius: 15px;
      transition: width .4s;
      transition-timing-function: ease;
    }
    .c-item-5:not(.c-item-hover){
      width: 150px;
      transition: all .4s;
      transition-timing-function: ease;
    }
    .c-item-cover {
      width: 150px;
      height: 400px;
      border-radius: 14px;
      opacity: .6;
      transition: all .4s;
      transition-timing-function: ease;
    }
    .c-item-1.c-item-cover,.c-item-2.c-item-cover,.c-item-3.c-item-cover,.c-item-4.c-item-cover{
      //background-position: 50%;
    }
  }
}
.warp-box {
  margin: 0 auto;
  border: none;
  .public-title{
    margin: 52px auto 20px;
  }
}
::v-deep.swiper-box {
  width: 1200px;
  position: relative;
  .swiper-button-next {
    display: none;
  }
  .swiper-button-prev {
    // left: -50px;
    display: none;
  }
  .swiper-container {
    position: unset;
    width: 1080px !important;
    height: 100% !important;
    margin: 0 auto;
    // margin-left: 0px;
  }
  .swiper-slide {
    width: 240px !important; 
    height: 240px;
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 300ms;
    margin-left: 10px;
    margin-right: 20px !important;
    margin-top: -40px;
  }
  .con {
    margin-top: 20px;
  }
  .box {
    width: 237px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    img {
      width: 120px;
      height: 120px;
    }
  }
  .tip {
    // width: 266px;
    opacity: 0.6;
  }
  &:hover {
    .swiper-button-next {
      // right: -50px;
      display: block;
    }
    .swiper-button-prev {
      // left: -50px;
      display: block;
    }
  }
}
</style>